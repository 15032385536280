// alle namen + beschreibung der offenen wetten, mit info bis wann man wetten kann + num. teilnehmer

<template>
  <div class="flex flex-col max-w-md items-center mx-auto p-5">
    <div v-if="!bets">
      ... Wetten werden geladen ...
    </div>
    <template v-else>
      <router-link
        v-for="(bet) in bets"
        :key="bet.id"
        class="text-white my-5 border p-5 cursor-pointer rounded-xl bg-gradient-to-r"
        :class="`from-gray-600 to-${bet.color}-500 hover:from-${bet.color}-400`"
        :to="`/${bet.id}`"
      >
        <div class="h-15 font-bold text-2xl mb-5">
          {{ bet.name }}
        </div>
        <div v-if="!bet.correct_answers" class="flex flex-wrap">
          <div class="flex-grow mb-3">
            Errechneter Geburtstermin: <b>{{ bet.expected_date_of_birth }}</b>
          </div>
          <div class="flex-grow" v-html="bet.last_possible_bet_date">
          </div>
        </div>
        <div v-else class="text-center">
          <b>🎉 Das Baby ist da! 🎉</b>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import { Directus } from '@directus/sdk'
import dayjs from 'dayjs'
const directus = new Directus('https://data.babybet.de/')

export default {
  name: 'Home',
  data() { return {
    bets: null,
  }},
  async created() {
    const { data } = await directus.items('bet').readMany({
      fields: '*.*',
    })
    this.bets = data.map(({ expected_date_of_birth, last_possible_bet_date, ...bet }) => {
      let lastPossibleBetDateString = `Wetten werden bis <b>${dayjs(expected_date_of_birth).format('DD.MM.YYYY')}</b> angenommen`
      if (dayjs(last_possible_bet_date).diff(dayjs()) <= 0 && !bet.correct_answers) {
        lastPossibleBetDateString = 'Wetten ist nicht mehr möglich.'
      }

      return {
        ...bet,
        expected_date_of_birth: dayjs(expected_date_of_birth).format('DD.MM.YYYY'),
        last_possible_bet_date: lastPossibleBetDateString,
      }
    })
  },
  computed: {
  },
}
</script>
