<template>
  <Breadcrumps />
  <div class="flex flex-col mx-auto items-center my-10 px-3 min-h-screen">
    <div v-if="!bet">
      ... loading ...
    </div>
    <template v-else>
      <div
        class="text-2xl text-center text-white mb-5 border p-5 cursor-pointer rounded-xl bg-gradient-to-r"
        :class="`from-gray-600 to-${bet.color}-500`"
      >
        {{ bet.name }}
      </div>
      <div class="text-xl text-center mb-5">
        Geplanter Geburtstermin: <b>{{ expectedDateOfBirth }}</b>
      </div>
      <div class="max-w-lg">
        <div v-if="bet.correct_answers" class="my-10">
          <div class="text-4xl text-center mb-3">🎉 Das Baby ist da! 🎉</div>
          <div v-if="bet.baby_photo && bet.baby_photo.id" class="flex justify-center">
            <img
              :src="`https://data.babybet.de/assets/${bet.baby_photo.id}?fit=inside&width=800&height=600&withoutEnlargement=true`"
              alt="Bild vom Baby"
              class="rounded-3xl my-5"
            >
          </div>
          <div class="text-center font-bold mb-1">Ergebnis:</div>
          <div class="mx-auto flex flex-col items-center text-lg">
            <div v-if="bet.options.includes('firstname')">
              <i>Vorname:</i> <b>{{ bet.correct_answers.bet_firstname }}</b>
            </div>
            <div v-if="bet.options.includes('weight')">
              <i>Gewicht:</i> {{ bet.correct_answers.bet_weight }}g
            </div>
            <div v-if="bet.options.includes('size')">
              <i>Größe:</i> {{ bet.correct_answers.bet_size }}cm
            </div>
            <div v-if="bet.options.includes('birthday')">
              <i>Geburtstag:</i> {{ bet.correct_answers.bet_birthday }}
            </div>
            <div v-if="bet.options.includes('birthtime')">
              <i>Geburtszeit:</i> {{ bet.correct_answers.bet_birthtime }}
            </div>
          </div>
          <div v-if="winners" class="text-center mt-10">
            <div>
              Gewonnen mit {{ winners[0].points === 1 ? '1 Punkt' : `${winners[0].points} Punkten` }} {{ winners.length > 1 ? 'haben' : 'hat' }}:
            </div>
            <div
              v-for="winner in winners"
              :key="winner.id"
              class="font-bold font-lg"
            >
              {{ winner.name_person }}
            </div>
            <div>Gratulation!</div>
            <div class="text-center mt-5">
              <router-link
                :to="`/${betId}/list?s=${winnersSearchParameter}`"
                class="text-center text-whiteborder p-2 text-md cursor-pointer rounded bg-gradient-to-r mx-auto text-white"
                :class="`to-${bet.color}-500 from-${bet.color}-500 hover:from-${bet.color}-600 hover:to-gray-600`"
              >
                {{ winners.length > 1 ? 'Tipps der Sieger' : 'Siegertipp' }} anzeigen
              </router-link>
            </div>
          </div>
        </div>
        <div class="text-center font-bold mb-1">Beschreibung:</div>
        <div class="mb-3 text-center">
          {{ bet.description }}
        </div>
        <div class="text-center mb-7">
          <template v-if="lastPossibleBetDate.possible">
            Wetten werden bis <b>{{ lastPossibleBetDate.date }}</b> angenommen
          </template>
          <template v-else>
            Wetten ist nicht mehr möglich.
          </template>
        </div>
        <div v-if="!bet.correct_answers && lastPossibleBetDate.possible" class="text-center mb-20">
          <router-link
            :to="`/${betId}/set`"
            class="text-center text-whiteborder p-2 text-md cursor-pointer rounded bg-gradient-to-r mx-auto text-white"
            :class="`to-${bet.color}-500 from-${bet.color}-500 hover:from-${bet.color}-600 hover:to-gray-600`"
          >
            Neue Wette abgeben
          </router-link>
        </div>
        <div class="text-center font-bold mb-1">Teilnehmer:</div>
        <div class="mb-3 text-center font-medium text-6xl">
          {{ bet.answers.length }}
        </div>
        <div class="font-bold mb-7 max-w-md mx-auto text-center">
          Bitte gib zuerst deine Wette ab bevor du dir die anderen Teilnehmer ansiehst. Du könntest sonst in deiner Entscheidung beeinflusst werden und es wäre nicht fair den anderen gegenüber!
        </div>
        <div class="text-center mb-5">
          <router-link
            :to="`/${betId}/list`"
            class="text-center text-whiteborder p-2 text-md cursor-pointer rounded bg-gradient-to-r mx-auto text-white"
            :class="`to-${bet.color}-500 from-${bet.color}-500 hover:from-${bet.color}-600 hover:to-gray-600`"
          >
            Zeig mir alle Teilnehmer
          </router-link>
        </div>
      </div>
    </template>
  </div>
  <Breadcrumps />
</template>

<script>
import { Directus } from '@directus/sdk'
import dayjs from 'dayjs'
import Breadcrumps from '../components/breadcrumps'
const directus = new Directus('https://data.babybet.de/')

export default {
  name: 'Bet',
  components: {
    Breadcrumps,
  },
  props: {
    betId: String,
  },
  data() {
    return {
      bet: null,
    }
  },
  async created() {
    this.bet = await directus.items('bet').readOne(this.betId, {
      fields: '*.*,baby_photo'
    })
  },
  computed: {
    winners() {
      if (this.bet && this.bet.correct_answers) {
        const winners = {}
        this.bet.answers.forEach(({ id, name_person }) => {
          winners[id] = {
            name_person,
            points: 0.0,
          }
        })

        if (this.bet.options.includes('firstname')) {
          const firstnameWinners = []
          let fullPoints = true
          const correctAnswer = this.bet.correct_answers.bet_firstname.trim().toLowerCase()
          this.bet.answers.forEach(({ id, bet_firstname }) => {
            if (bet_firstname.trim().toLowerCase() == correctAnswer) {
              firstnameWinners.push(id)
            }
          })
          if (!firstnameWinners.length) {
            fullPoints = false
            this.bet.answers.forEach(({ id, bet_firstname }) => {
              if (bet_firstname.trim().toLowerCase().substr(0, 1) == correctAnswer.substr(0, 1)) {
                firstnameWinners.push(id)
              }
            })
          }
          firstnameWinners.forEach((id) => {
            winners[id].points += fullPoints ? 1 : 0.5
          })
        }

        if (this.bet.options.includes('weight')) {
          let weightWinners = []
          let nearestDiff = null
          const correctAnswer = Number(this.bet.correct_answers.bet_weight)
          this.bet.answers.forEach(({ id, bet_weight }) => {
            if (nearestDiff === null) {
              nearestDiff = Math.abs(correctAnswer - bet_weight)
              weightWinners.push(id)
            } else if (Math.abs(correctAnswer - bet_weight) == nearestDiff) {
              weightWinners.push(id)
            } else if (Math.abs(correctAnswer - bet_weight) < nearestDiff) {
              weightWinners = [id]
              nearestDiff = Math.abs(correctAnswer - bet_weight)
            }
          })
          weightWinners.forEach((id) => {
            winners[id].points += 1
          })
        }

        if (this.bet.options.includes('size')) {
          let sizeWinners = []
          let nearestDiff = null
          const correctAnswer = Number(this.bet.correct_answers.bet_size)
          this.bet.answers.forEach(({ id, bet_size }) => {
            if (nearestDiff === null) {
              nearestDiff = Math.abs(correctAnswer - bet_size)
              sizeWinners.push(id)
            } else if (Math.abs(correctAnswer - bet_size) == nearestDiff) {
              sizeWinners.push(id)
            } else if (Math.abs(correctAnswer - bet_size) < nearestDiff) {
              sizeWinners = [id]
              nearestDiff = Math.abs(correctAnswer - bet_size)
            }
          })
          sizeWinners.forEach((id) => {
            winners[id].points += 1
          })
        }

        if (this.bet.options.includes('birthday')) {
          let birthdayWinners = []
          let nearestDiff = null
          const correctAnswer = dayjs(this.bet.correct_answers.bet_birthday)
          this.bet.answers.forEach(({ id, bet_birthday }) => {
            const dayjs_bet_birthday = dayjs(bet_birthday)
            if (nearestDiff === null) {
              nearestDiff = Math.abs(correctAnswer.diff(dayjs_bet_birthday))
              birthdayWinners.push(id)
            } else if (Math.abs(correctAnswer.diff(dayjs_bet_birthday)) == nearestDiff) {
              birthdayWinners.push(id)
            } else if (Math.abs(correctAnswer.diff(dayjs_bet_birthday)) < nearestDiff) {
              birthdayWinners = [id]
              nearestDiff = Math.abs(correctAnswer.diff(dayjs_bet_birthday))
            }
          })
          birthdayWinners.forEach((id) => {
            winners[id].points += 1
          })
        }

        if (this.bet.options.includes('birthtime')) {
          let birthtimeWinners = []
          let nearestDiff = null
          const correctAnswer = dayjs(this.bet.correct_answers.bet_birthtime)
          this.bet.answers.forEach(({ id, bet_birthtime }) => {
            const dayjs_bet_birthtime = dayjs(bet_birthtime)
            if (nearestDiff === null) {
              nearestDiff = Math.abs(correctAnswer.diff(dayjs_bet_birthtime))
              birthtimeWinners.push(id)
            } else if (Math.abs(correctAnswer.diff(dayjs_bet_birthtime)) == nearestDiff) {
              birthtimeWinners.push(id)
            } else if (Math.abs(correctAnswer.diff(dayjs_bet_birthtime)) < nearestDiff) {
              birthtimeWinners = [id]
              nearestDiff = Math.abs(correctAnswer.diff(dayjs_bet_birthtime))
            }
          })
          birthtimeWinners.forEach((id) => {
            winners[id].points += 1
          })
        }

        const maxPoints = Math.max(...Object.values(winners).map(({ points }) => points))

        return Object.entries(winners).map(([id, { name_person, points }]) => ({
          id,
          name_person,
          points
        })).filter(({ points }) => points == maxPoints)
      }
      return null
    },
    winnersSearchParameter() {
      if (this.winners && this.winners.length) {
        return encodeURIComponent(this.winners.map(({ name_person }) => `"${name_person}"`).join(','))
      }
      return null
    },
    lastPossibleBetDate() {
      if (this.bet) {
        return {
          date: dayjs(this.bet.last_possible_bet_date).format('DD.MM.YYYY'),
          possible: dayjs(this.bet.last_possible_bet_date).diff(dayjs()) > 0,
        }
        

      }
      return null
    },
    expectedDateOfBirth() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).format('DD.MM.YYYY')
      } else {
        return '-'
      }
    }
  }
}
</script>
