<template>
  <Breadcrumps />
  <div class="flex flex-col mx-auto items-center my-10 px-3 min-h-screen">
    <div v-if="!bet">
      ... loading ...
    </div>
    <div v-else-if="saving">
      ... Wette wird gespeichert ...
    </div>
    <form v-else @submit.prevent="setBet" class="px-1">
      <div
        class="text-2xl text-center text-white mb-10 border p-5 cursor-pointer rounded-xl bg-gradient-to-r"
        :class="`from-gray-600 to-${bet.color}-500`"
      >
        {{ bet.name }}
      </div>
      <div class="font-bold mb-10 text-2xl text-center">
        Neuen Tipp abgeben
      </div>
      <div class="text-xl text-center mb-5">
        Geplanter Geburtstermin: <b>{{ expectedDateOfBirth }}</b>
      </div>
      <div class="max-w-md py-5 mx-auto">
        <div class="border-black border-b pb-5 mb-5">
          <div>
            Dein Name*
          </div>
          <div class="text-sm text-gray-500">
            Wie ist dein Name? Sollte eindeutig zu erkennen sein.
          </div>
          <div>
            <input type="text" class="border border-black rounded p-2 w-full" placeholder="Dein Name" required v-model="name_person" />
          </div>
        </div>
        <div class="border-black border-b pb-5 mb-5">
          <div>
            E-Mail <span class="text-gray-500">(optional)</span>
          </div>
          <div class="text-sm text-gray-500">
            Wie ist deine E-Mail Adresse? Dann bekommst du das Ergebnis per Mail zugesendet. Deine E-Mail Adresse ist für andere Teilnehmer nicht sichtbar.
          </div>
          <div>
            <input type="email" class="border border-black rounded p-2 w-full" placeholder="E-Mail" v-model="email" />
          </div>
        </div>
        <div v-if="bet.options.includes('firstname')" class="border-black border-b pb-5 mb-5">
          <div>
            Vorname*
          </div>
          <div class="text-sm text-gray-500">
            Wie wird der Vorname des Babies sein?
          </div>
          <div class="flex">
            <input type="text" class="border border-black rounded p-2 flex-1" :placeholder="`z.B.: ${randomFirstname}`" required v-model="firstname" />
            <button type="button" class="outline-none ml-2" @click="getRandomFirstname()">🔄</button>
          </div>
        </div>
        <div v-if="bet.options.includes('weight')" class="border-black border-b pb-5 mb-5">
          <div>
            Gewicht*
          </div>
          <div class="text-sm text-gray-500">
            Wie viel wird das Baby wiegen? - in Gramm ohne Kommastelle
          </div>
          <div>
            <input type="number" class="border border-black rounded p-2 w-full" placeholder="Gewicht in g" required v-model="weight" />
          </div>
        </div>
        <div v-if="bet.options.includes('size')" class="border-black border-b pb-5 mb-5">
          <div>
            Größe*
          </div>
          <div class="text-sm text-gray-500">
            Wie groß wird das Baby sein? - in Zentimeter ohne Kommastelle
          </div>
          <div>
            <input type="number" class="border border-black rounded p-2 w-full" placeholder="Größe in cm" required v-model="size" />
          </div>
        </div>
        <div v-if="bet.options.includes('birthday')" class="border-black border-b pb-5 mb-5">
          <div>
            Geburtstag*
          </div>
          <div class="text-sm text-gray-500">
            An welchem Datum wird das Baby auf die Welt kommen?
          </div>
          <div class="text-sm">
            Geplanter Geburtstermin: <b>{{ expectedDateOfBirth }}</b>
          </div>
          <div>
            <input type="date" class="border border-black rounded p-2 w-full" placeholder="Geburtstag" :min="minBirthday" :max="maxBirthday" required v-model="birthday" />
          </div>
        </div>
        <div v-if="bet.options.includes('birthtime')" class="border-black border-b pb-5 mb-5">
          <div>
            Geburtszeit*
          </div>
          <div class="text-sm text-gray-500">
            Zur welcher Uhrzeit wird das Baby geboren?
          </div>
          <div>
            <input type="time" class="border border-black rounded p-2 w-full" placeholder="Geburtszeit" required v-model="birthtime" />
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <router-link type="button" class="flex justify-center items-center bg-gray-400 flex-1 rounded mr-0 sm:mr-5 hover:bg-opacity-90 p-4 order-2 sm:order-1" :to="`/${betId}`">
            Abbrechen
          </router-link>
          <button
            type="submit"

            class="flex justify-center items-center bg-gray-400 flex-1 rounded hover:bg-opacity-90 p-4 bg-gradient-to-r text-white mb-5 sm:mb-0 order-1 sm:order-2"
            :class="`to-${bet.color}-500 from-${bet.color}-500 hover:from-${bet.color}-600 hover:to-gray-600`"
          >
            Wette platzieren
          </button>
        </div>
      </div>
    </form>
  </div>
  <Breadcrumps />
</template>

<script>
import dayjs from 'dayjs'
import { Directus } from '@directus/sdk'
import Breadcrumps from '../components/breadcrumps'
import vornamenM from '../vornamen/vornamen_m_koeln_2017'
const directus = new Directus('https://data.babybet.de/')

export default {
  name: 'SetBet',
  components: {
    Breadcrumps,
  },
  props: {
    betId: String,
    getAllBets: Function,
  },
  data: () => ({
    name_person: '',
    email: '',
    firstname: '',
    weight: '',
    size: '',
    birthday: '',
    birthtime: '',
    saving: false,
    bet: null,
    vornamenM,
    randomFirstname: '',
  }),
  async created() {
    this.bet = await directus.items('bet').readOne(this.betId)
    this.getRandomFirstname()
  },
  computed: {
    expectedDateOfBirth() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).format('DD.MM.YYYY')
      } else {
        return '-'
      }
    },
    minBirthday() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).subtract(1, 'month').format('YYYY-MM-DD')
      } else {
        return null
      }
    },
    maxBirthday() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).add(1, 'months').format('YYYY-MM-DD')
      }
      return null
    },
  },
  methods: {
    async setBet() {
      this.saving = true
      const bet = {
        bet_id: this.bet.id,
        name_person: this.name_person,
        email: this.email,
      }
      if (this.bet.options.includes('firstname')) {
        bet.bet_firstname = this.firstname
      }
      if (this.bet.options.includes('weight')) {
        bet.bet_weight = Number(this.weight)
      }
      if (this.bet.options.includes('size')) {
        bet.bet_size = Number(this.size)
      }
      if (this.bet.options.includes('birthday')) {
        bet.bet_birthday = this.birthday
      }
      if (this.bet.options.includes('birthtime')) {
        bet.bet_birthtime = `${this.birthtime}:00`
      }

      await directus.items('bet_entry').createOne(bet)
      this.$router.push(`/${this.betId}/setSuccess`)
    },
    getRandomFirstname() {
      this.randomFirstname = this.vornamenM[Math.floor(Math.random() * this.vornamenM.length)]
    }
  }
}
</script>