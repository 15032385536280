<template>
  <div class="min-h-screen flex flex-col">
    <Breadcrumps />
    <div v-if="!bet"  class="max-w-md mx-auto p-5">
      ... loading ...
    </div>
    <div v-else class="flex flex-col mx-auto items-center my-10 px-3">
      <div
        class="text-2xl text-center mb-5 text-white border p-5 cursor-pointer rounded-xl bg-gradient-to-r"
        :class="`from-gray-600 to-${bet.color}-500`"
      >
        {{ bet.name }}
      </div>
      <div class="font-bold mb-10 text-4xl text-center">
        Teilnehmer
      </div>
      <div v-if="bet.answers.length" class="w-full mx-auto max-w-md">
        <input
          type="text"
          v-model="search"
          placeholder="Teilnehmer suchen..."
          class="border border-black rounded p-2 w-full"
        />
      </div>
      <div v-if="!bet.answers.length">
        Leider hat noch niemand teilgenommen :(
        <div>
          Komm mach mit!
        </div>
        <div v-if="!bet.correct_answers" class="text-center mt-5 mb-20">
          <router-link
            :to="`/${betId}/set`"
            class="text-center text-whiteborder p-2 text-md cursor-pointer rounded bg-gradient-to-r mx-auto text-white"
            :class="`to-${bet.color}-500 from-${bet.color}-500 hover:from-${bet.color}-600 hover:to-gray-600`"
          >
            Neue Wette abgeben
          </router-link>
        </div>
      </div>
      <div v-else class="mx-auto p-5 flex flex-wrap justify-start max-w-5xl">
        <div
          v-for="(answer, key) in searchedAnswers"
          :key="`answer${key}`"
          class="border rounded border-black p-5 flex-1 mr-5 mb-5 max-w-sm whitespace-nowrap"
        >
          <div class="text-center font-bold mb-3">
            {{ answer.name_person }}
          </div>
          <div class="pl-5">
            <ul class="list-disc list-outside">
              <li v-if="bet.options.includes('firstname')">
                <i>Vorname:</i> {{ answer.bet_firstname }}
              </li>
              <li v-if="bet.options.includes('weight')">
                <i>Gewicht:</i> {{ answer.bet_weight }}g
              </li>
              <li v-if="bet.options.includes('size')">
                <i>Größe:</i> {{ answer.bet_size }}cm
              </li>
              <li v-if="bet.options.includes('birthday')">
                <i>Geburtstag:</i> {{ answer.bet_birthday }}
              </li>
              <li v-if="bet.options.includes('birthtime')">
                <i>Geburtszeit:</i> {{ answer.bet_birthtime }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mb-10 text-gray-600 max-w-md mx-auto mt-auto">
      Du hast teilgenommen, aber möchtest deine Wette und Daten löschen? <a class="underline cursor-pointer hover:text-black" href="mailto:wendelin@peleska.at?subject=Babybet Daten löschen" target="_blank">Bitte schreib mir eine E-Mail.</a>
    </div>
    <Breadcrumps />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { Directus } from '@directus/sdk'
import Breadcrumps from '../components/breadcrumps'
const directus = new Directus('https://data.babybet.de/')

export default {
  name: 'Participants',
  components: {
    Breadcrumps,
  },
  props: {
    view: String,
    betId: String,
  },
  data() {
    return {
      search: '',
      bet: null,
    }
  },
  async created() {
    this.bet = await directus.items('bet').readOne(this.betId, {
      fields: '*.*'
    })
    if (this.$router.currentRoute.value.query && this.$router.currentRoute.value.query.s) {
      this.search = this.$router.currentRoute.value.query.s
    }
  },
  computed: {
    searchedAnswers() {
      const searchArray = this.search.split(',')

      return this.bet ? this.bet.answers
        .filter(({ name_person }) => {
          let found = false
          searchArray.forEach((term) => {
            const trimmedTerm = term.trim()
            if (trimmedTerm.startsWith('"') && trimmedTerm.endsWith('"')) {
              if (name_person.trim().toLowerCase() === trimmedTerm.replaceAll('"', '').toLowerCase()) {
                found = true
              }
            } else if (name_person.trim().toLowerCase().indexOf(trimmedTerm.toLowerCase()) > -1) {
              found = true
            }
          })
          return found
        })
        .map((answer) => ({
          ...answer,
          bet_birthday: answer.bet_birthday ? dayjs(answer.bet_birthday).format('DD.MM.YYYY') : null,
          bet_birthtime: answer.bet_birthtime ? answer.bet_birthtime.substr(0, 5) : null,
        }))
        .sort(({ name_person: a }, { name_person: b }) => a.localeCompare(b)) : []
    },
    expectedDateOfBirth() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).format('DD.MM.YYYY')
      } else {
        return '-'
      }
    }
  },
}
</script>
