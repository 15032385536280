import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Bet from '../views/Bet.vue'
import SetBet from '../views/SetBet.vue'
import SetBetSuccess from '../views/SetBetSuccess.vue'
import Participants from '../views/Participants.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:betId',
    name: 'Wette',
    component: Bet,
    props: true,
  },
  {
    path: '/:betId/set',
    name: 'Wette abgeben',
    component: SetBet,
    props: true,
  },
  {
    path: '/:betId/setSuccess',
    name: 'Wette abgegeben',
    component: SetBetSuccess,
    props: true,
  },
  {
    path: '/:betId/list',
    name: 'Teilnehmer',
    component: Participants,
    props: true,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView();
  }
})

export default router
